import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'
import AboutMe from '../components/aboutMe'
import {toText} from '../helpers/strings'


const About = (props) => {
  const {frontmatter, html} = props.data.markdownRemark
  const title = frontmatter.title
  const content = toText(html)

  
  return (

    <Layout>
      <Seo title="About" />
      <AboutMe title={title} content={content} />
    </Layout>
  )

}

export const query = graphql`
  query AboutPage {
    markdownRemark(frontmatter: {path: {eq: "/about.md"}}) {
      html
      frontmatter {
        path
        title
      }
    }
  }

`

export default About