import React from 'react'
import {SiJavascript, SiHtml5, SiCss3, SiBootstrap, SiReact, SiRails, SiGatsby, SiRuby} from 'react-icons/si'



const iconColors = {
  js: '#f7df1e', //yellow
  html: '#f06529', //orange
  css: '#2965f1', // blue
  bs: '#563d7c', // purple
  react: '#61DBFB', // lightblue
  ruby: '#cc0000', // red
  gatsby: '#663399'
}

const Logos = () => { 

  return (
    <div id="logoWrapper">
      
      <SiJavascript fill={iconColors.js}/>
      
      <SiHtml5 fill={iconColors.html}/>
      
      
      <SiCss3 fill={iconColors.css}/>
      
      <SiBootstrap fill={iconColors.bs}/>
      
      <SiReact fill={iconColors.react}/>
      
      <SiRails fill={iconColors.ruby}/>
      
      <SiGatsby fill={iconColors.gatsby}/>
      
      <SiRuby fill={iconColors.ruby}/>
      
    </div>
  )
}

export default Logos