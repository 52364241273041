import React from 'react';
import './about.css';
import {StaticImage} from 'gatsby-plugin-image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Logos from './logos'

const AboutMe = (props) => (
  <Container>
    <Row>
        <div id="imageContainer">
          <StaticImage 
            id="myImage" 
            src="../images/me3.png" 
            alt="me" 
            placeholder="blurred" 
            layout="fixed" 
            width={200} 
            height={200}
          />
        </div>
    </Row>
    
    <Row>
      <div id="contentWrapper">
        {props.content}
        
      </div>
    </Row>
    <Row>

      <Logos />
    </Row>
    
    
  </Container>
)

export default AboutMe