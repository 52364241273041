export const stringIntersection = (s1, s2) => {
  let intersection = [];
  let sizes;
  s1.length < s2.length ? sizes = {sm: s1, big: s2} : sizes = {sm: s2, big: s1}
  let {sm, big} = sizes

  for (let i = 0; i < sm.length; i++) {
    let char = sm[i];
    if (big.indexOf(char) > -1 && intersection.indexOf(char) === -1) {
      intersection.push(char)
    }
  }
  return intersection
}

// strips tags from text
export const toText = (str) => {
  if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}